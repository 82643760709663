import { useState } from 'react'

const Stops = ({ size, children, current }) => {
    const [stop, setStop] = useState(current !== undefined ? current : Stops.defaultProps.current)

    return children({
        size: size || Stops.defaultProps.size,
        current: stop,
        setStop,
    })
}

Stops.defaultProps = {
    current: 1,
    size: 3,
}

export default Stops
