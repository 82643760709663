import React from 'react'
import styled from 'styled-components'

const LightBlueTheme = {
    background: '#E7E7E7',
    activeBackground: '#0324FF',
}

const DarkBlueTheme = {
    background: '#EFEFEF',
    activeBackground: '#0C009A',
}

const WhiteTheme = {
    background: '#525252',
    activeBackground: '#FFFFFF',
}

const Light = styled.div`
    border-radius: inherit;
    height: 100%;
    opacity: 0;
    transition: 250ms ease-in;
    transition-property: opacity, transform;
    width: 100%;
`

const Inner = styled.div`
    background-color: ${({ theme }) => theme.background};
    border-radius: 2px;
    height: 4px;
    width: 48px;

    ${Light} {
        background-color: ${({ theme }) => theme.activeBackground};
    }

    &[data-active='true'] {
        ${Light} {
            opacity: 1;
        }
    }
`

const UnstyledDot = ({ active = false, index, onClick, theme = LightBlueTheme, ...props }) => (
    <button
        {...props}
        type="button"
        onClick={(e) => {
            e.stopPropagation()
            onClick(index)
        }}
    >
        <Inner theme={theme} data-active={!!active}>
            <Light />
        </Inner>
    </button>
)

const Dot = styled(UnstyledDot)`
    background-color: rgba(255, 0, 0, -0.1);
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0.5rem 0.25rem;

    :hover,
    :active {
        ${Light} {
            opacity: 1;
        }
    }
`

export default Object.assign(Dot, {
    LightBlueTheme,
    DarkBlueTheme,
    WhiteTheme,
})
