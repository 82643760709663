import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Dot from './Dot'

const UnstyledSliderNav = ({ size: sizeProp, onChange, current, theme: themeProp, ...props }) => {
    const contextTheme = useContext(ThemeContext)
    const size = sizeProp || UnstyledSliderNav.defaultProps.size

    return (
        <div {...props}>
            {Array(...Array(size)).map((el, i) => (
                <Dot
                    active={current === i}
                    index={i}
                    theme={themeProp || contextTheme || Dot.LightBlueTheme}
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={i}
                    onClick={onChange || UnstyledSliderNav.defaultProps.onChange}
                />
            ))}
        </div>
    )
}

const SliderNav = styled(UnstyledSliderNav)`
    display: flex;
    width: fit-content;
    margin: 0 auto;
`

SliderNav.defaultProps = {
    size: 3,
    onChange: () => {},
}

export default Object.assign(SliderNav, {
    LightBlueTheme: Dot.LightBlueTheme,
    DarkBlueTheme: Dot.DarkBlueTheme,
    WhiteTheme: Dot.WhiteTheme,
})
